.projects {
  /* Contacts overview */
  .cell {
    &.project-id {
      min-width: 292px;
      width: 30%;

      strong {
        color: #ae1eac;
      }
    }
    &.client {
      min-width: 350px;
      width: 10%;
    }
    &.phone-number {
      min-width: 150px;
      width: 30%;
    }
    &.date {
      min-width: 105px;
      width: 30%;
    }
  }
}
.new-group {
    margin-bottom: 18px;
}
.grouped-models {
  display: inline-block;
}
.projects-droparea {
  margin: 0;

  &.ui-state-hover {
    background: #F9FBFE;
  }

  &.ui-droppable-hover {
    background: #C4D08B;
  }
}
.ui-draggable-handle {
    cursor: move;
}
.ui-draggable-dragging {
    display: inline-block;
    background-color: rgba(0, 0, 0, .1);
    z-index: 9999;
}
.non-grouped-models {
    min-height: 50px;
}
.remove-introduced-models {
    cursor: pointer;
}
.grouped-models-container {
    .group {
        display: flex;
        flex-direction: column;
        background-color: #F9FBFE;

        .group-title {
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #617b98;
            font-style: italic;
            margin: 10px 18px;
            display: flex;
            justify-content: space-between;
        }
        .grouped-models {
            margin-top: 0;
        }
    }

}



.content-block {
    ul {
        padding: 0;
        margin-bottom: 18px;
        li {
            list-style: none;
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            font-weight: 200;
            line-height: 26px;
            margin: 0 18px;
        }
    }
}

.content-block-models {
    display: flex;
    flex-direction: column;

    h2 {
        margin-bottom: 0 !important;
    }

    ul {
        margin: 0;
        flex-grow: 1;
        padding: 10px 0;

        li {

        }
    }
}

.selected-booked-model,
.payment-booked-model {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.open-close {
    .grouped-models {
        display:none;
    }
}

.new-group-input {
    display: flex;
    padding: 0 18px;
    justify-content: center;
    align-items: center;
    align-content: center;

    .groupname {
        flex-grow: 1;
    }
    .plus-add-models-to-group {
        flex-shrink: 0;
        margin-left: 18px;
        margin-bottom: 0 !important;
    }
}
