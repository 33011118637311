.login {
    width: 35%;
    margin-top: 35px;
    background: #627A97;
    border-radius: 3px;
    border: 1px solid #808080;
    padding: 15px;

    .form-group {
        margin-bottom: 15px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input {
            width: 100%;
            border-radius: 3px;
            padding: 5px;
            border: 1px solid #808080;
            height: 35px;
        }
    }

    .button {
        border: 1px solid #2e6da4;
        height: 35px;
        border-radius: 3px;
        background: #337ab7;
        color: #ffffff;
        padding: 10px;
    }

    .text-right {
        text-align: right;
    }
}
