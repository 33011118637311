@charset "UTF-8";

@font-face {
  font-family: "model-support";
  src:url("/fonts/model-support/model-support.eot");
  src:url("/fonts/model-support/model-support.eot?#iefix") format("embedded-opentype"),
  url("/fonts/model-support/model-support.woff") format("woff"),
  url("/fonts/model-support/model-support.ttf") format("truetype"),
  url("/fonts/model-support/model-support.svg#model-support") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "model-support" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "model-support" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\61";
}
.icon-arrow-down:before {
  content: "\62";
}
.icon-arrow-up:before {
  content: "\63";
}
.icon-arrowdown:before {
  content: "\64";
}
.icon-arrowup:before {
  content: "\65";
}
.icon-check:before {
  content: "\66";
}
.icon-checkbox-empty:before {
  content: "\67";
}
.icon-delete:before {
  content: "\68";
}
.icon-edit:before {
  content: "\69";
}
.icon-globe:before {
  content: "\6a";
}
.icon-image:before {
  content: "\6b";
}
.icon-kruis-1:before {
  content: "\6c";
}
.icon-logo-pixel-industries:before {
  content: "\6d";
}
.icon-mail:before {
  content: "\6e";
}
.icon-plus-1:before {
  content: "\6f";
}
.icon-search:before {
  content: "\70";
}
.icon-wijzigselectie:before {
  content: "\71";
}
.icon-add-contact:before {
  content: "\72";
}
.icon-new:before {
  content: "\73";
}
.icon-set:before {
  content: "\75";
}
.icon-move:before {
  content: "\76";
}
.icon-view:before {
  content: "\74";
}
.icon-fake:before {
  content: "\6b";
  color: transparent;
}
