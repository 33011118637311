.remove-extra-project-costs-button {
    color: #bdc0c2;
    cursor: pointer;
    margin-bottom: 9px;

    &:hover {
        color: #627A97;
    }
}
.item-content-container {
    .item-header-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e1;

        .icon-kruis-1 {
            margin-right: 29px;
            font-size: 13px;
            color: #bdc0c2;
            cursor: pointer;
        }
    }
    .tab-content {
        padding: 24px 36px 41px;
        flex-direction: row;

        .content-block {
            border: 1px solid #ece8e8;
            margin-right: 12px;
            flex: 1;
            max-width: 100%;
            width: 50%;

            &.content-block--full-width {
                width: 100%;
            }
            &.content-block-models {
                flex: 2;
                max-width: unset;
            }
            &.model-notes-block {
                flex: 1.7;
            }
            &:last-of-type {
                margin-right: 0;
            }
            h2 {
                border-bottom: 1px solid #ece8e8;
                font-family: 'Roboto', sans-serif;
                font-size: 15px;
                font-weight: 400;
                color: #617b98;
                height: 42px;
                line-height: 42px;
                margin: 0;
                padding-left: 18px;
                margin-bottom: .8em;
            }

            h3 {
                font-family: 'Roboto', sans-serif;
                margin-left: 18px;
                font-weight: 500;
                font-size: 16px;
            }

            p {
                margin: 8px 17px 31px;
                line-height: 26px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #3e3c3c;
            }
            input {
                padding: 5px;
                border: 1px solid #ece8e8;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
            }
            .icon-plus-1 {
                color: #bdbebf;
                cursor: pointer;
                overflow: auto;
                display: inline-block;
                margin-bottom: -6px;
            }
            table {
                margin: 8px 17px 31px;
                border-collapse: collapse;

                td {
                    line-height: 26px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    color: #3e3c3c;
                    vertical-align: top;
                    padding-right: 15px;
                    word-break: break-word;

                    &:first-of-type {
                        white-space: nowrap;
                    }
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            .model-avatar {
                width: 116px;
                height: 174px;
                border-radius: 4px;
                margin: 16px 19px 24px 19px;
                background-size: cover;
                background-position: center;
            }
            .entry-info-container {
                line-height: 21px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #3e3c3c;
                padding-left: 18px;
                margin-right: 18px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;

                .value {
                    color: #3c3a3a;
                    font-weight: 100;
                    width: 50%;
                    margin-bottom: 13px;
                }
                .download-select {
                    width: 70%;
                }
                .icon-globe, .icon-mail {
                    color: #aaabac;
                    font-size: 17px;
                    margin-right: .5em;
                }
                .title {
                    width: 50%;
                    margin-bottom: 13px;
                }
                .download-title {
                    width: 100%;
                    margin-bottom: 18px;
                }
                .generate-pdf-button {
                    color: #617b98;
                    margin-bottom: 13px;
                }
            }
            .costs-container {
                line-height: 21px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #3e3c3c;
                padding-left: 18px;
                margin-right: 18px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                input {
                    margin: 5px 15px 5px 0px;
                    &:last-of-type {
                        margin-left: 5px;
                    }
                }

                .add-model_settlement {
                    .icon-arrow-up {
                        transform: rotate(90deg);
                        color: #617b98;
                        display: block;
                    }
                }
                .icon-kruis-1 {
                    color: #bdc0c2;
                }
            }
            .costs-total-container {
                line-height: 21px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                font-weight: 500;
                color: #3e3c3c;
                padding-left: 18px;
                margin: 25px 18px 18px 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
            }
            .model-linked-settlements-block {
                line-height: 21px;
                font-family: 'Roboto', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #3e3c3c;
                padding-left: 18px;
                margin-right: 18px;
                margin-bottom: 5px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;

                .model-linked-settlements-block-item {
                    flex: 3;

                    &.settlements-block-item-price {
                        flex: 1;
                        text-align: right;
                    }
                }
            }
        }

        .content-blocks-container {
            .content-block {
                margin-bottom: 12px;

                &:last-of-type {
                    margin-right: 12px;
                    margin-bottom: 0;
                }
            }
        }

        .switchable-content {
            position: relative;
            min-width: 220px;

            &.switchable-content--maxheight {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 700px;
            }

            .content-switcher {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                cursor: pointer;

                span {
                    color: #a6acb2;
                    font-size: 12px;
                    position: absolute;
                    top: 14px;
                    right: 14px;

                    i {
                        font-size: 9px;
                    }
                }
                ul {
                    position: absolute;
                    top: 41px;
                    left: -1px;
                    right: -1px;
                    margin: 0;
                    padding: 0;
                    display: none;
                    border: 1px solid #ece8e8;

                    li {
                        height: 42px;
                        line-height: 42px;
                        border-bottom: 1px solid #ece8e8;
                        font-family: 'Roboto', sans-serif;
                        font-size: 15px;
                        font-weight: 400;
                        color: #617b98;
                        margin: 0;
                        padding-left: 18px;
                        list-style-type: none;
                        background: #fff;
                        cursor: pointer;

                        &:last-of-type {
                            border-bottom: 0;
                        }
                    }
                }
            }
            .switchable-content-block {
                display: none;

                &:first-of-type {
                    display: block;
                }
                h2 {
                    cursor: pointer;
                }
            }
        }
    }
}
.extra-project-costs-container {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;

    #extra-costs-title {
        width: 65%;
    }
    .extra-costs-values {
        display: flex;

        #extra-costs-value {
            width: 50px;
            text-align: right;
        }
    }


    .add-extra-project-costs-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;

        #add-extra-project-costs-label {
            padding: 5px;
            border: 1px solid #ece8e8;
            width: 65%;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin-left: 18px;
        }

        #add-extra-project-costs-value {
            padding: 5px;
            border: 1px solid #ece8e8;
            width: 20%;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
        }
        #add-extra-project-costs-label:placeholder {
            color: #bdbebf;
        }
        #add-extra-project-costs-value:placeholder {
            color: #bdbebf;
        }
        .add-extra-project-costs-button-box {
            transform: rotate(90deg);
            width: 20px;
            height: 20px;
            margin-right: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon-arrow-up {
                color: #627A97;
            }
        }
    }
}
.price-container {
    margin-left: 18px;
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    margin-right: 18px;
    font-weight: 400;
}
.selected-booked-model-container {
    input {
        font-weight: 200;
    }
    .extra-costs-block {
        margin-bottom: 5px;
    }
    .costs-total-container {
        font-family: 'Roboto', sans-serif;
        display: flex;
        justify-content: space-between;
        margin: 18px;
        font-weight: 500;
        border-top: 1px solid #ece8e8;
        padding-top: 18px;

        .costs-total-value {
            width: 168px;
        }
    }

    .costs-container {
        margin-left: 18px;
        display: flex;
        align-items: center;


        .icon-kruis-1 {
            color: #627A97;
            cursor: pointer;
            font-size: 13px;
            font-weight: 100;
            margin-left: 7px;
        }

        .add-extra-costs-label {
            width: 60%;
            margin-right: 18px;
        }
        .add-extra-costs-value {
            width: 20%;
            margin-left: 5px;
        }

        .costs-add-extra-costs {
            transform: rotate(90deg);
            width: 20px;
            height: 20px;
            cursor: pointer;

            .icon-arrow-up {
                color: #627A97;
            }
        }
    }
}
.models-payed {
    margin: 18px 5px 18px 18px;
}
.models-payed-label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}
.payment-booked-model-container {

    .costs-container {
        display: flex;
        justify-content: space-between;
        margin: 5px 18px;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: 200;

        .costs-value-block {
            display: flex;
            width: 78px;

            div:first-of-type {
                margin-right: 9px;
            }
        }
    }
    .costs-total-container {
        display: flex;
        justify-content: space-between;
        margin: 5px 18px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        border-top: 1px solid #ece8e8;
        padding-top: 18px;
        margin-top: 18px;
    }
    .costs-download-specs-block {
        margin: 18px;
    }
    .for-model {
        color: #627A97;

        .costs-value input{
            width: 65px;
        }
        .booked-model-costs-field {
            color: #627A97;
        }
    }
}

.project-payed {
    margin-left: 18px;
}
.project-payed-label {
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}
