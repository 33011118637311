.accordion {
    width: 100%;
    margin-bottom: 10px;

    input[type=checkbox].accordion-state {
        display: none;
    }

    &.open {
        .handle {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .toggle-accordion .icon-arrowdown {
                transform: rotate(0deg);
            }
            .options {
                button.text-only, button.advanced-search-button {
                    &.active {
                        background-color: rgba(255, 255, 255, 1);
                        color: #8495AA;
                    }
                }
            }
        }
    }

    .handle {
        background-color: #627A97;
        border-radius: 4px;
        padding: 0;
        color: white;
        font-weight: 300;
        height: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        font-family: 'Roboto', sans-serif;

        button {
            text-align: center;
        }

        .toggle-accordion {
            color: white;
            cursor: pointer;
            padding: 0 25px;
            height: 100%;
            display: flex;
            align-items: center;

            &:hover .icon-arrowdown {
                transform: rotate(-90deg);
            }

            &:active .icon-arrowdown {
                transform: rotate(-90deg);
            }

            .icon-arrowdown {
                transition: transform .1s ease-in-out;
                transform: rotate(-90deg);
                transform-origin: center center;
                margin-right: 25px;
            }
        }

        .title {
            font-size: 21px;
            width: 140px;
            user-select: none;
            font-family: 'Helvetica Neue', sans-serif;
        }

        .search-form {
            height: 100%;
            position: relative;
            background-color: #6F87A4;
            width: 325px;
            display: flex;
            padding: 0 10px 0 30px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 400;

            @include placeholder {
                user-select: none;
            }

            input[type=search] {
                height: 100%;
                border: none;
                color: white;
                font-size: 16px;
                background-color: transparent;
                flex-grow: 1;
                flex-shrink: 1;
            }
            input[type=search]::-webkit-input-placeholder {
                color: rgba(255, 255, 255, .5);
            }
            button[type=submit] {
                color: white;
                font-size: 13px;
                height: 100%;
                width: 50px;
                flex-grow: 0;
                flex-shrink: 0;
                padding: 0;

                .icon-search {
                    display: block;
                    transition: transform .1s ease-in-out;
                    transform: scale(1);
                    transform-origin: 50% 60%;
                }

                &:hover .icon-search {
                    transform: scale(1.1);
                }

                &:active .icon-search {
                    transform: scale(1.3);
                }

            }
        }
        .options {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            height: 100%;

            button {
                padding: 0 20px;
                font-size: 18px;
                font-weight: 300;
                color: white;
                position: relative;
                margin-left: -1px;

                &:not(:first-of-type):not(:last-of-type) {
                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        display: inline-block;
                        width: 1px;
                        height: 20px;
                        background-color: rgba(255, 255, 255, .5);
                    }

                    &:before {
                        left: 0;
                    }
                    &:after {
                        right: 0;
                    }
                }
            }

            button.text-only {
                background-color: rgba(255, 255, 255, 0);
                transition: background-color .2s ease-in-out;

                &:hover:not(.active) {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }

            button.add {
                &:hover .icon-add {
                    transform: scale(1.1);
                }

                &:active .icon-add {
                    transform: scale(1.3);
                }

                .icon-add {
                    display: inline-block;
                    height: 35px;
                    transition: transform .1s ease-in-out;
                    transform: scale(1);
                    transform-origin: center center;
                }
            }

            .icon-add {
                font-size: 27px;
                color: white;

                &:before {
                    vertical-align: middle;
                }
            }
        }
    }

    .drawer {
        display: none;
        border-left: 1px solid #DBD8D8;
        border-right: 1px solid #DBD8D8;
        background: #fff;

        &.drawer-open {
            display: block;
        }

        footer {
            .pagination {
                width: 45px;
                -webkit-flex-shrink: 0;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                -webkit-flex-grow: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                text-align: center;
                font-size: 23px;
                padding: 11px 10px;
                color: #6F87A4;
                cursor: pointer;

                .icon-arrowdown {
                    transition: -webkit-transform .05s ease-in-out;
                    transition: transform .05s ease-in-out;
                    -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                    -webkit-transform-origin: center center;
                    transform-origin: center center;
                    display: inline-block;
                }

                &.prev {
                    .icon-arrowdown {
                        transition: -webkit-transform .05s ease-in-out;
                        transition: transform .05s ease-in-out;
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }
                }

                &.disabled {
                    color: #ebeef1;
                    cursor: default;
                }
            }
        }
    }
}
