.gallery {
  flex: 1;

  .entry-info-container {
    padding: 19px;

    .gallery-title {
      float: left;
      width: 130px;
      margin-bottom: 13px;
    }
    .gallery-value {
      color: #3c3a3a;
      font-weight: 100;
      margin-bottom: 13px;
    }
    &.no-flex {
      display: block !important;
    }
  }
  .dropzone {
    border: 1px dashed #d5d3d3;
    background: #fdfcfc;
    display: flex;
    flex: 1;
    min-height: 191px;
    justify-content: center;
    align-items: center;
    color: #979797;
    font-size: 15px;
      padding: 10px;
  }
  .photos-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 9px 38px;
    margin: 0;
    list-style-type: none;

    .dz-preview {
      margin: 0 10px 20px;
      width: 116px;

      .dz-image {
        width: 116px;
        height: 174px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;

        i.icon-move {
          color: #607a99;
          font-size: 22px;
          width: 32px;
          border-radius: 3px;
          background: #fff;
          text-align: center;
          display: none;

          &:before {
            line-height: 32px;
          }
        }
        &:hover {
          i.icon-move {
            display: block;
          }
        }
      }
      &.is-deleting {
        opacity: 0.5;
      }
    }
    div.dz-preview {
      display: none;
    }
  }
  .photo-template, .dz-details {
    display: none;
  }
  .action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 8px;

    i {
      background: #efefef;
      border-radius: 2px;
      color: #a6a6a6;
      width: 26px;
      text-align: center;

      &:before {
        line-height: 26px;
      }
      &:hover {
        background: #adcdf2;
      }
      &.active {
        background: #579ae9;
        color: #fff;
      }
      &:hover {
        background: #adcdf2;
      }
    }
  }
}
