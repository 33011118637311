/*
 |  Mixins
 */
/*
 |  Fonts
 */
@font-face {
  font-family: "model-support";
  src: url("/fonts/model-support/model-support.eot");
  src: url("/fonts/model-support/model-support.eot?#iefix") format("embedded-opentype"), url("/fonts/model-support/model-support.woff") format("woff"), url("/fonts/model-support/model-support.ttf") format("truetype"), url("/fonts/model-support/model-support.svg#model-support") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "model-support" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "model-support" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add:before {
  content: "\61"; }

.icon-arrow-down:before {
  content: "\62"; }

.icon-arrow-up:before {
  content: "\63"; }

.icon-arrowdown:before {
  content: "\64"; }

.icon-arrowup:before {
  content: "\65"; }

.icon-check:before {
  content: "\66"; }

.icon-checkbox-empty:before {
  content: "\67"; }

.icon-delete:before {
  content: "\68"; }

.icon-edit:before {
  content: "\69"; }

.icon-globe:before {
  content: "\6a"; }

.icon-image:before {
  content: "\6b"; }

.icon-kruis-1:before {
  content: "\6c"; }

.icon-logo-pixel-industries:before {
  content: "\6d"; }

.icon-mail:before {
  content: "\6e"; }

.icon-plus-1:before {
  content: "\6f"; }

.icon-search:before {
  content: "\70"; }

.icon-wijzigselectie:before {
  content: "\71"; }

.icon-add-contact:before {
  content: "\72"; }

.icon-new:before {
  content: "\73"; }

.icon-set:before {
  content: "\75"; }

.icon-move:before {
  content: "\76"; }

.icon-view:before {
  content: "\74"; }

.icon-fake:before {
  content: "\6b";
  color: transparent; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCIX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/-L14Jk06m6pUHB-5mXQQnYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/I3S1wsgSg9YCurV6PUkTOYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/NYDWBdD4gIq26G5XYbHsFIX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Pru33qjShpZSmG3z6VYwnYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfZBw1xU1rKptJj_0jans920.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/sTdaA6j0Psb920Vjv-mrzH-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/uYECMKoHcO9x1wdmbyHIm3-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/tnj4SB6DNbdaQnsM8CFqBX-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/_VYFx-s824kXq_Ul2BHqYH-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/NJ4vxlgWwWbEsv18dAhqnn-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/Ks_cVxiCiwUWVsFWFA3Bjn-_kf6ByYO6CLYdB4HQE-Y.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v15/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/ZLqKeelYbATG60EpZBSDy4X0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/oHi30kwQWvpCWqAhzHcCSIX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/rGvHdJnr2l75qb0YND9NyIX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/mx9Uck6uB63VIKFYnEMXrYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/mbmhprMH69Zi6eEPBYVFhYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/oOeFwZNlrTefzLYmlVV1UIX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUZBw1xU1rKptJj_0jans920.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/*
 |  Elements
 */
.button,
button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  user-select: none; }

.table {
  display: flex;
  flex-direction: column; }
  .table .table-row {
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid #E0E0E1;
    padding: 0 10px; }
    .table .table-row .cell {
      font-size: 15px;
      padding: 16px 10px;
      color: #3c3a3a;
      transition: color .2s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'Roboto', sans-serif;
      font-weight: 300; }
      .table .table-row .cell.toggle {
        width: 45px;
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center; }
      .table .table-row .cell.actions {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        justify-content: flex-end;
        padding: 0;
        min-width: 100px; }
        .table .table-row .cell.actions.actions-2 {
          min-width: 65px; }
        .table .table-row .cell.actions.actions-4 {
          min-width: 135px; }
        .table .table-row .cell.actions.actions-5 {
          min-width: 205px; }
        .table .table-row .cell.actions button {
          padding: 0 10px;
          font-size: 14px;
          transition: all .1s ease-in-out;
          transform: scale(1);
          transform-origin: center center; }
          .table .table-row .cell.actions button:hover {
            color: #617B98;
            transform: scale(1.1); }
        .table .table-row .cell.actions .icon-globe {
          width: 20px;
          display: flex;
          height: 20px;
          align-items: center;
          justify-content: center;
          border-radius: 50%; }
        .table .table-row .cell.actions .icon-globe.green {
          color: white;
          background-color: #C4D08B; }
      .table .table-row .cell .grey {
        color: #929292; }
      .table .table-row .cell .green {
        color: green; }
  .table > header.table-row,
  .table > footer.table-row {
    background-color: #F6F6F6; }
    .table > header.table-row .cell,
    .table > footer.table-row .cell {
      color: #3c3a3a; }
      .table > header.table-row .cell.sortable,
      .table > footer.table-row .cell.sortable {
        color: #617B98;
        cursor: pointer;
        user-select: none;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        position: relative; }
        .table > header.table-row .cell.sortable.active,
        .table > footer.table-row .cell.sortable.active {
          font-weight: 500; }
          .table > header.table-row .cell.sortable.active:after,
          .table > footer.table-row .cell.sortable.active:after {
            font-size: 10px;
            position: absolute;
            content: '\63';
            font-family: "model-support" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 18px;
            -webkit-font-smoothing: antialiased;
            padding-left: 4px; }
        .table > header.table-row .cell.sortable.active-desc:after,
        .table > footer.table-row .cell.sortable.active-desc:after {
          content: '\62'; }
  .table > main > section,
  .table #advanced-model-search-results > main > section {
    display: flex;
    background-color: white; }
    .table > main > section:nth-child(even),
    .table #advanced-model-search-results > main > section:nth-child(even) {
      background-color: #F9FBFE; }
    .table > main > section:hover .cell,
    .table #advanced-model-search-results > main > section:hover .cell {
      color: #607a99; }
    .table > main > section.collapsible,
    .table #advanced-model-search-results > main > section.collapsible {
      flex-direction: column; }
      .table > main > section.collapsible > input[type=checkbox],
      .table #advanced-model-search-results > main > section.collapsible > input[type=checkbox] {
        display: none; }
      .table > main > section.collapsible > main,
      .table #advanced-model-search-results > main > section.collapsible > main {
        display: none;
        background-color: #fff; }
      .table > main > section.collapsible .icon-arrowdown,
      .table #advanced-model-search-results > main > section.collapsible .icon-arrowdown {
        transition: transform .05s ease-in-out;
        transform: rotate(-90deg);
        transform-origin: center center;
        display: inline-block; }
      .table > main > section.collapsible.open .icon-arrowdown,
      .table #advanced-model-search-results > main > section.collapsible.open .icon-arrowdown {
        transform: rotate(0deg); }
  .table > footer.table-row {
    border-bottom: 1px solid #DBD8D8;
    border-radius: 4px; }
    .table > footer.table-row .cell {
      text-align: center;
      flex-grow: 1; }

.indication-dot {
  display: inline-block;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: #dbd8d8; }
  .indication-dot.red {
    background-color: #dd2323; }
  .indication-dot.purple {
    background-color: #ae1eac; }
  .indication-dot.blue {
    background-color: #1e79ae; }
  .indication-dot.green {
    background-color: #00cb63; }

.form-container .action-bar {
  background: #f6f6f6;
  border-bottom: 1px solid #ece8e8;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 29px; }
  .form-container .action-bar:last-of-type {
    border-top: 1px solid #ece8e8; }

.form-container h1, .form-container button {
  line-height: 31px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500; }

.form-container h1 {
  display: inline-block;
  color: #20587b;
  margin: 0;
  line-height: 48px; }

.form-container .btn-group button {
  padding: 1px 29px 2px; }

.form-container .btn-group button.cancel {
  color: #a8abae; }

.form-container .btn-group button.store {
  color: #fff;
  background: #5f9be2; }

.form-container .fieldsets-container {
  padding: 33px 35px 0; }
  .form-container .fieldsets-container:last-of-type {
    padding-bottom: 71px; }

.form-container .fieldsets-container, .form-container .fields-splits-container, .form-container .form-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .form-container .fieldsets-container.justify-flex-start, .form-container .fields-splits-container.justify-flex-start, .form-container .form-line.justify-flex-start {
    justify-content: flex-start; }

.form-container section.fieldset {
  background: #fafafa;
  border: 1px solid #ece8e8;
  padding: 0;
  margin-right: 13px;
  flex: 1; }
  .form-container section.fieldset hr {
    border: none;
    border-bottom: 1px solid #ece8e8; }
  .form-container section.fieldset.for-textarea {
    display: flex;
    flex-direction: column; }
  .form-container section.fieldset:last-of-type {
    margin-right: 0; }
  .form-container section.fieldset .fields-split {
    margin: 21px 16px 35px; }
  .form-container section.fieldset h2 {
    border-bottom: 1px solid #ece8e8;
    line-height: 43px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding-left: 17px;
    margin: 0;
    color: #20587b; }
  .form-container section.fieldset .warning {
    color: #dd2323;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400; }
  .form-container section.fieldset input, .form-container section.fieldset label, .form-container section.fieldset .fake-label {
    box-sizing: border-box;
    line-height: 21px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px; }
  .form-container section.fieldset input,
  .form-container section.fieldset select,
  .form-container section.fieldset textarea {
    border: 1px solid #ddd; }
    .form-container section.fieldset input:focus,
    .form-container section.fieldset select:focus,
    .form-container section.fieldset textarea:focus {
      border-color: #627A97; }
  .form-container section.fieldset .form-line > input, .form-container section.fieldset .checkboxes-selected {
    border: 1px solid #ece8e8;
    color: #3e3c3c;
    width: 232px;
    min-height: 31px;
    padding: 0 5px; }
    .form-container section.fieldset .form-line > input:focus, .form-container section.fieldset .checkboxes-selected:focus {
      border-color: #cac5c5; }
    .form-container section.fieldset .form-line > input.in-error, .form-container section.fieldset .checkboxes-selected.in-error {
      border-color: #dd2323; }
  .form-container section.fieldset label, .form-container section.fieldset .fake-label {
    padding-right: 20px;
    line-height: 31px;
    white-space: nowrap;
    display: inline-block;
    color: #3e3c3c; }
  .form-container section.fieldset textarea {
    border: 0;
    flex: 1;
    padding: 10px 15px;
    box-sizing: border-box;
    line-height: 21px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400; }
  .form-container section.fieldset .fields {
    color: #3e3c3c;
    width: 232px;
    min-height: 31px;
    position: relative; }
    .form-container section.fieldset .fields.fields-padding {
      padding: 0 5px; }
    .form-container section.fieldset .fields label {
      padding-right: 10px; }
    .form-container section.fieldset .fields .checkboxes-selected {
      color: #3e3c3c;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 31px; }
      .form-container section.fieldset .fields .checkboxes-selected span:after {
        content: ', '; }
      .form-container section.fieldset .fields .checkboxes-selected span:last-of-type:after {
        content: ''; }
    .form-container section.fieldset .fields .dropdown-closer {
      display: none;
      position: absolute;
      z-index: 2;
      top: 39px;
      right: 9px;
      cursor: pointer; }
    .form-container section.fieldset .fields ul.checkboxes-dropdown {
      display: none;
      position: absolute;
      z-index: 1;
      margin: 0;
      padding: 0;
      top: 30px;
      left: 0;
      right: 0;
      background: #fff;
      border: 1px solid #ece8e8; }
      .form-container section.fieldset .fields ul.checkboxes-dropdown li {
        border-bottom: 1px solid #ece8e8;
        list-style-type: none;
        line-height: 31px;
        padding-left: 10px; }
        .form-container section.fieldset .fields ul.checkboxes-dropdown li:last-of-type {
          border-bottom: 0; }

form input[type="checkbox"], form input[type="radio"] {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

form input[type="checkbox"] + label, form input[type="radio"] + label {
  display: block;
  cursor: pointer;
  position: relative;
  color: #888;
  line-height: 17px; }

form input[type="checkbox"] + label:before {
  font-family: model-support;
  content: "\67";
  color: #bbb;
  display: inline-block;
  width: 16px;
  height: 20px;
  margin-right: 6px;
  vertical-align: top; }

form input[type="radio"] + label:before {
  content: '';
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  vertical-align: text-top; }

form input[type="radio"] + label:after {
  content: '';
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  left: 3px;
  width: 11px;
  height: 11px;
  top: 10px;
  transition: background-color .2s linear 0s; }

form input[type="checkbox"]:checked + label:before {
  font-family: model-support;
  content: "\66";
  color: #555; }

form input[type="radio"]:checked + label:after {
  background-color: #617b98; }

.styledSelect {
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  border: 1px solid #ece8e8;
  width: 232px;
  overflow: hidden;
  background-color: #fff; }
  .styledSelect.in-error {
    border-color: #dd2323;
    border-width: 3px; }
  .styledSelect:after {
    font-family: model-support;
    content: "\64";
    color: #aaa;
    position: absolute;
    top: 0;
    line-height: 31px;
    right: 12px;
    font-size: 12px;
    text-align: right;
    pointer-events: none; }

.styledSelect select {
  padding: 4px 73px 4px 8px;
  width: 130%;
  border: none;
  color: #767676;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.styledSelect select:focus {
  outline: none; }

.search-options-template {
  display: none; }

.form-container {
  width: 100%; }

.search-options-container .search-option-line {
  justify-content: flex-start; }

.search-option-line .styledSelect {
  margin-right: 10px; }

.advanced-search-option-values {
  display: none; }
  .advanced-search-option-values.show {
    display: inline-block; }

.search-button {
  color: #fff;
  background: #5f9be2;
  padding: 2px 20px;
  border-radius: 2px; }
  .search-button:hover {
    background: #498ede; }

.search-view-switch {
  color: #404040;
  background: #eee;
  padding: 2px 20px;
  border-radius: 2px; }
  .search-view-switch:hover {
    background: #498ede; }

.reset-button {
  color: #b8333a;
  background: #eee;
  padding: 2px 20px;
  border-radius: 2px; }
  .reset-button:hover {
    background: #e1e1e1; }

.delete-search-row {
  color: red;
  font-weight: normal; }

.alert {
  width: 35%;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  list-style: none; }

.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

/*
 |  Pages
 */
.navigation.top {
  background-color: white;
  width: 100%;
  padding: 30px 0;
  flex-shrink: 0;
  position: fixed;
  z-index: 10; }
  .navigation.top .row {
    justify-content: space-between; }
  .navigation.top hgroup {
    align-self: flex-start; }
  .navigation.top .title,
  .navigation.top .subtitle {
    font-family: 'Roboto', sans-serif;
    font-weight: 300; }
  .navigation.top .title {
    font-size: 30px;
    color: #535151; }
  .navigation.top .subtitle {
    font-size: 19px;
    color: #989494; }
  .navigation.top .button {
    align-self: center;
    flex-shrink: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    border: 1px solid #989494;
    border-radius: 3px;
    padding: 9px 20px; }

.navigation.bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  flex-shrink: 0;
  margin-top: 60px; }
  .navigation.bottom .row {
    justify-content: flex-end; }
  .navigation.bottom .icon-logo-pixel-industries {
    font-size: 42px;
    color: #D9D9D9;
    text-decoration: none;
    transition: color .2s;
    padding-bottom: 5px; }
    .navigation.bottom .icon-logo-pixel-industries:hover {
      color: black; }
    .navigation.bottom .icon-logo-pixel-industries:before {
      vertical-align: middle; }

.accordion {
  width: 100%;
  margin-bottom: 10px; }
  .accordion input[type=checkbox].accordion-state {
    display: none; }
  .accordion.open .handle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .accordion.open .handle .toggle-accordion .icon-arrowdown {
      transform: rotate(0deg); }
    .accordion.open .handle .options button.text-only.active, .accordion.open .handle .options button.advanced-search-button.active {
      background-color: white;
      color: #8495AA; }
  .accordion .handle {
    background-color: #627A97;
    border-radius: 4px;
    padding: 0;
    color: white;
    font-weight: 300;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    font-family: 'Roboto', sans-serif; }
    .accordion .handle button {
      text-align: center; }
    .accordion .handle .toggle-accordion {
      color: white;
      cursor: pointer;
      padding: 0 25px;
      height: 100%;
      display: flex;
      align-items: center; }
      .accordion .handle .toggle-accordion:hover .icon-arrowdown {
        transform: rotate(-90deg); }
      .accordion .handle .toggle-accordion:active .icon-arrowdown {
        transform: rotate(-90deg); }
      .accordion .handle .toggle-accordion .icon-arrowdown {
        transition: transform .1s ease-in-out;
        transform: rotate(-90deg);
        transform-origin: center center;
        margin-right: 25px; }
    .accordion .handle .title {
      font-size: 21px;
      width: 140px;
      user-select: none;
      font-family: 'Helvetica Neue', sans-serif; }
    .accordion .handle .search-form {
      height: 100%;
      position: relative;
      background-color: #6F87A4;
      width: 325px;
      display: flex;
      padding: 0 10px 0 30px;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400; }
      .accordion .handle .search-form ::-webkit-input-placeholder {
        user-select: none; }
      .accordion .handle .search-form :-moz-placeholder {
        user-select: none; }
      .accordion .handle .search-form ::-moz-placeholder {
        user-select: none; }
      .accordion .handle .search-form :-ms-input-placeholder {
        user-select: none; }
      .accordion .handle .search-form input[type=search] {
        height: 100%;
        border: none;
        color: white;
        font-size: 16px;
        background-color: transparent;
        flex-grow: 1;
        flex-shrink: 1; }
      .accordion .handle .search-form input[type=search]::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5); }
      .accordion .handle .search-form button[type=submit] {
        color: white;
        font-size: 13px;
        height: 100%;
        width: 50px;
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0; }
        .accordion .handle .search-form button[type=submit] .icon-search {
          display: block;
          transition: transform .1s ease-in-out;
          transform: scale(1);
          transform-origin: 50% 60%; }
        .accordion .handle .search-form button[type=submit]:hover .icon-search {
          transform: scale(1.1); }
        .accordion .handle .search-form button[type=submit]:active .icon-search {
          transform: scale(1.3); }
    .accordion .handle .options {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      height: 100%; }
      .accordion .handle .options button {
        padding: 0 20px;
        font-size: 18px;
        font-weight: 300;
        color: white;
        position: relative;
        margin-left: -1px; }
        .accordion .handle .options button:not(:first-of-type):not(:last-of-type):before, .accordion .handle .options button:not(:first-of-type):not(:last-of-type):after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: inline-block;
          width: 1px;
          height: 20px;
          background-color: rgba(255, 255, 255, 0.5); }
        .accordion .handle .options button:not(:first-of-type):not(:last-of-type):before {
          left: 0; }
        .accordion .handle .options button:not(:first-of-type):not(:last-of-type):after {
          right: 0; }
      .accordion .handle .options button.text-only {
        background-color: rgba(255, 255, 255, 0);
        transition: background-color .2s ease-in-out; }
        .accordion .handle .options button.text-only:hover:not(.active) {
          background-color: rgba(255, 255, 255, 0.1); }
      .accordion .handle .options button.add:hover .icon-add {
        transform: scale(1.1); }
      .accordion .handle .options button.add:active .icon-add {
        transform: scale(1.3); }
      .accordion .handle .options button.add .icon-add {
        display: inline-block;
        height: 35px;
        transition: transform .1s ease-in-out;
        transform: scale(1);
        transform-origin: center center; }
      .accordion .handle .options .icon-add {
        font-size: 27px;
        color: white; }
        .accordion .handle .options .icon-add:before {
          vertical-align: middle; }
  .accordion .drawer {
    display: none;
    border-left: 1px solid #DBD8D8;
    border-right: 1px solid #DBD8D8;
    background: #fff; }
    .accordion .drawer.drawer-open {
      display: block; }
    .accordion .drawer footer .pagination {
      width: 45px;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      text-align: center;
      font-size: 23px;
      padding: 11px 10px;
      color: #6F87A4;
      cursor: pointer; }
      .accordion .drawer footer .pagination .icon-arrowdown {
        transition: -webkit-transform .05s ease-in-out;
        transition: transform .05s ease-in-out;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        display: inline-block; }
      .accordion .drawer footer .pagination.prev .icon-arrowdown {
        transition: -webkit-transform .05s ease-in-out;
        transition: transform .05s ease-in-out;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg); }
      .accordion .drawer footer .pagination.disabled {
        color: #ebeef1;
        cursor: default; }

.remove-extra-project-costs-button {
  color: #bdc0c2;
  cursor: pointer;
  margin-bottom: 9px; }
  .remove-extra-project-costs-button:hover {
    color: #627A97; }

.item-content-container .item-header-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e1; }
  .item-content-container .item-header-actions .icon-kruis-1 {
    margin-right: 29px;
    font-size: 13px;
    color: #bdc0c2;
    cursor: pointer; }

.item-content-container .tab-content {
  padding: 24px 36px 41px;
  flex-direction: row; }
  .item-content-container .tab-content .content-block {
    border: 1px solid #ece8e8;
    margin-right: 12px;
    flex: 1;
    max-width: 100%;
    width: 50%; }
    .item-content-container .tab-content .content-block.content-block--full-width {
      width: 100%; }
    .item-content-container .tab-content .content-block.content-block-models {
      flex: 2;
      max-width: unset; }
    .item-content-container .tab-content .content-block.model-notes-block {
      flex: 1.7; }
    .item-content-container .tab-content .content-block:last-of-type {
      margin-right: 0; }
    .item-content-container .tab-content .content-block h2 {
      border-bottom: 1px solid #ece8e8;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: #617b98;
      height: 42px;
      line-height: 42px;
      margin: 0;
      padding-left: 18px;
      margin-bottom: .8em; }
    .item-content-container .tab-content .content-block h3 {
      font-family: 'Roboto', sans-serif;
      margin-left: 18px;
      font-weight: 500;
      font-size: 16px; }
    .item-content-container .tab-content .content-block p {
      margin: 8px 17px 31px;
      line-height: 26px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #3e3c3c; }
    .item-content-container .tab-content .content-block input {
      padding: 5px;
      border: 1px solid #ece8e8;
      font-family: 'Roboto', sans-serif;
      font-size: 14px; }
    .item-content-container .tab-content .content-block .icon-plus-1 {
      color: #bdbebf;
      cursor: pointer;
      overflow: auto;
      display: inline-block;
      margin-bottom: -6px; }
    .item-content-container .tab-content .content-block table {
      margin: 8px 17px 31px;
      border-collapse: collapse; }
      .item-content-container .tab-content .content-block table td {
        line-height: 26px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #3e3c3c;
        vertical-align: top;
        padding-right: 15px;
        word-break: break-word; }
        .item-content-container .tab-content .content-block table td:first-of-type {
          white-space: nowrap; }
        .item-content-container .tab-content .content-block table td:last-of-type {
          padding-right: 0; }
    .item-content-container .tab-content .content-block .model-avatar {
      width: 116px;
      height: 174px;
      border-radius: 4px;
      margin: 16px 19px 24px 19px;
      background-size: cover;
      background-position: center; }
    .item-content-container .tab-content .content-block .entry-info-container {
      line-height: 21px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #3e3c3c;
      padding-left: 18px;
      margin-right: 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .item-content-container .tab-content .content-block .entry-info-container .value {
        color: #3c3a3a;
        font-weight: 100;
        width: 50%;
        margin-bottom: 13px; }
      .item-content-container .tab-content .content-block .entry-info-container .download-select {
        width: 70%; }
      .item-content-container .tab-content .content-block .entry-info-container .icon-globe, .item-content-container .tab-content .content-block .entry-info-container .icon-mail {
        color: #aaabac;
        font-size: 17px;
        margin-right: .5em; }
      .item-content-container .tab-content .content-block .entry-info-container .title {
        width: 50%;
        margin-bottom: 13px; }
      .item-content-container .tab-content .content-block .entry-info-container .download-title {
        width: 100%;
        margin-bottom: 18px; }
      .item-content-container .tab-content .content-block .entry-info-container .generate-pdf-button {
        color: #617b98;
        margin-bottom: 13px; }
    .item-content-container .tab-content .content-block .costs-container {
      line-height: 21px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #3e3c3c;
      padding-left: 18px;
      margin-right: 18px;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .item-content-container .tab-content .content-block .costs-container input {
        margin: 5px 15px 5px 0px; }
        .item-content-container .tab-content .content-block .costs-container input:last-of-type {
          margin-left: 5px; }
      .item-content-container .tab-content .content-block .costs-container .add-model_settlement .icon-arrow-up {
        transform: rotate(90deg);
        color: #617b98;
        display: block; }
      .item-content-container .tab-content .content-block .costs-container .icon-kruis-1 {
        color: #bdc0c2; }
    .item-content-container .tab-content .content-block .costs-total-container {
      line-height: 21px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #3e3c3c;
      padding-left: 18px;
      margin: 25px 18px 18px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
    .item-content-container .tab-content .content-block .model-linked-settlements-block {
      line-height: 21px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #3e3c3c;
      padding-left: 18px;
      margin-right: 18px;
      margin-bottom: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .item-content-container .tab-content .content-block .model-linked-settlements-block .model-linked-settlements-block-item {
        flex: 3; }
        .item-content-container .tab-content .content-block .model-linked-settlements-block .model-linked-settlements-block-item.settlements-block-item-price {
          flex: 1;
          text-align: right; }
  .item-content-container .tab-content .content-blocks-container .content-block {
    margin-bottom: 12px; }
    .item-content-container .tab-content .content-blocks-container .content-block:last-of-type {
      margin-right: 12px;
      margin-bottom: 0; }
  .item-content-container .tab-content .switchable-content {
    position: relative;
    min-width: 220px; }
    .item-content-container .tab-content .switchable-content.switchable-content--maxheight {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 700px; }
    .item-content-container .tab-content .switchable-content .content-switcher {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      cursor: pointer; }
      .item-content-container .tab-content .switchable-content .content-switcher span {
        color: #a6acb2;
        font-size: 12px;
        position: absolute;
        top: 14px;
        right: 14px; }
        .item-content-container .tab-content .switchable-content .content-switcher span i {
          font-size: 9px; }
      .item-content-container .tab-content .switchable-content .content-switcher ul {
        position: absolute;
        top: 41px;
        left: -1px;
        right: -1px;
        margin: 0;
        padding: 0;
        display: none;
        border: 1px solid #ece8e8; }
        .item-content-container .tab-content .switchable-content .content-switcher ul li {
          height: 42px;
          line-height: 42px;
          border-bottom: 1px solid #ece8e8;
          font-family: 'Roboto', sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #617b98;
          margin: 0;
          padding-left: 18px;
          list-style-type: none;
          background: #fff;
          cursor: pointer; }
          .item-content-container .tab-content .switchable-content .content-switcher ul li:last-of-type {
            border-bottom: 0; }
    .item-content-container .tab-content .switchable-content .switchable-content-block {
      display: none; }
      .item-content-container .tab-content .switchable-content .switchable-content-block:first-of-type {
        display: block; }
      .item-content-container .tab-content .switchable-content .switchable-content-block h2 {
        cursor: pointer; }

.extra-project-costs-container {
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .extra-project-costs-container #extra-costs-title {
    width: 65%; }
  .extra-project-costs-container .extra-costs-values {
    display: flex; }
    .extra-project-costs-container .extra-costs-values #extra-costs-value {
      width: 50px;
      text-align: right; }
  .extra-project-costs-container .add-extra-project-costs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px; }
    .extra-project-costs-container .add-extra-project-costs-container #add-extra-project-costs-label {
      padding: 5px;
      border: 1px solid #ece8e8;
      width: 65%;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      margin-left: 18px; }
    .extra-project-costs-container .add-extra-project-costs-container #add-extra-project-costs-value {
      padding: 5px;
      border: 1px solid #ece8e8;
      width: 20%;
      font-family: 'Roboto', sans-serif;
      font-size: 14px; }
    .extra-project-costs-container .add-extra-project-costs-container #add-extra-project-costs-label:placeholder {
      color: #bdbebf; }
    .extra-project-costs-container .add-extra-project-costs-container #add-extra-project-costs-value:placeholder {
      color: #bdbebf; }
    .extra-project-costs-container .add-extra-project-costs-container .add-extra-project-costs-button-box {
      transform: rotate(90deg);
      width: 20px;
      height: 20px;
      margin-right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      .extra-project-costs-container .add-extra-project-costs-container .add-extra-project-costs-button-box .icon-arrow-up {
        color: #627A97; }

.price-container {
  margin-left: 18px;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-right: 18px;
  font-weight: 400; }

.selected-booked-model-container input {
  font-weight: 200; }

.selected-booked-model-container .extra-costs-block {
  margin-bottom: 5px; }

.selected-booked-model-container .costs-total-container {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-between;
  margin: 18px;
  font-weight: 500;
  border-top: 1px solid #ece8e8;
  padding-top: 18px; }
  .selected-booked-model-container .costs-total-container .costs-total-value {
    width: 168px; }

.selected-booked-model-container .costs-container {
  margin-left: 18px;
  display: flex;
  align-items: center; }
  .selected-booked-model-container .costs-container .icon-kruis-1 {
    color: #627A97;
    cursor: pointer;
    font-size: 13px;
    font-weight: 100;
    margin-left: 7px; }
  .selected-booked-model-container .costs-container .add-extra-costs-label {
    width: 60%;
    margin-right: 18px; }
  .selected-booked-model-container .costs-container .add-extra-costs-value {
    width: 20%;
    margin-left: 5px; }
  .selected-booked-model-container .costs-container .costs-add-extra-costs {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    cursor: pointer; }
    .selected-booked-model-container .costs-container .costs-add-extra-costs .icon-arrow-up {
      color: #627A97; }

.models-payed {
  margin: 18px 5px 18px 18px; }

.models-payed-label {
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }

.payment-booked-model-container .costs-container {
  display: flex;
  justify-content: space-between;
  margin: 5px 18px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 200; }
  .payment-booked-model-container .costs-container .costs-value-block {
    display: flex;
    width: 78px; }
    .payment-booked-model-container .costs-container .costs-value-block div:first-of-type {
      margin-right: 9px; }

.payment-booked-model-container .costs-total-container {
  display: flex;
  justify-content: space-between;
  margin: 5px 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  border-top: 1px solid #ece8e8;
  padding-top: 18px;
  margin-top: 18px; }

.payment-booked-model-container .costs-download-specs-block {
  margin: 18px; }

.payment-booked-model-container .for-model {
  color: #627A97; }
  .payment-booked-model-container .for-model .costs-value input {
    width: 65px; }
  .payment-booked-model-container .for-model .booked-model-costs-field {
    color: #627A97; }

.project-payed {
  margin-left: 18px; }

.project-payed-label {
  font-size: 15px;
  font-family: 'Roboto', sans-serif; }

.row-accordion .toggle {
  text-align: center; }

.item-content-container ul.tabs {
  margin: 0;
  padding-left: 20px;
  height: 47px; }
  .item-content-container ul.tabs li {
    display: inline-block;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #bdbebf;
    height: 48px;
    line-height: 32px;
    padding: 13px 1px 0;
    margin: 0 17px; }
    .item-content-container ul.tabs li.active {
      color: #617b98;
      border-bottom: 2px solid #617b98; }

.item-content-container .tab-content {
  display: none;
  border-bottom: 1px solid #e0e0e1; }
  .item-content-container .tab-content:first-of-type {
    display: flex; }

.gallery {
  flex: 1; }
  .gallery .entry-info-container {
    padding: 19px; }
    .gallery .entry-info-container .gallery-title {
      float: left;
      width: 130px;
      margin-bottom: 13px; }
    .gallery .entry-info-container .gallery-value {
      color: #3c3a3a;
      font-weight: 100;
      margin-bottom: 13px; }
    .gallery .entry-info-container.no-flex {
      display: block !important; }
  .gallery .dropzone {
    border: 1px dashed #d5d3d3;
    background: #fdfcfc;
    display: flex;
    flex: 1;
    min-height: 191px;
    justify-content: center;
    align-items: center;
    color: #979797;
    font-size: 15px;
    padding: 10px; }
  .gallery .photos-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 9px 38px;
    margin: 0;
    list-style-type: none; }
    .gallery .photos-container .dz-preview {
      margin: 0 10px 20px;
      width: 116px; }
      .gallery .photos-container .dz-preview .dz-image {
        width: 116px;
        height: 174px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center; }
        .gallery .photos-container .dz-preview .dz-image i.icon-move {
          color: #607a99;
          font-size: 22px;
          width: 32px;
          border-radius: 3px;
          background: #fff;
          text-align: center;
          display: none; }
          .gallery .photos-container .dz-preview .dz-image i.icon-move:before {
            line-height: 32px; }
        .gallery .photos-container .dz-preview .dz-image:hover i.icon-move {
          display: block; }
      .gallery .photos-container .dz-preview.is-deleting {
        opacity: 0.5; }
    .gallery .photos-container div.dz-preview {
      display: none; }
  .gallery .photo-template, .gallery .dz-details {
    display: none; }
  .gallery .action-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 8px; }
    .gallery .action-bar i {
      background: #efefef;
      border-radius: 2px;
      color: #a6a6a6;
      width: 26px;
      text-align: center; }
      .gallery .action-bar i:before {
        line-height: 26px; }
      .gallery .action-bar i:hover {
        background: #adcdf2; }
      .gallery .action-bar i.active {
        background: #579ae9;
        color: #fff; }
      .gallery .action-bar i:hover {
        background: #adcdf2; }

.contacts {
  /* Contacts overview */ }
  .contacts .cell.company-name {
    min-width: 257px;
    width: 30%; }
  .contacts .cell.first-name {
    min-width: 142px;
    width: 10%; }
  .contacts .cell.last-name {
    min-width: 147px;
    width: 10%; }
  .contacts .cell.phone-number {
    min-width: 147px;
    width: 13%; }
  .contacts .cell.email {
    min-width: 270px;
    width: 30%; }

.table .table-row .cell.contactperson-indenting {
  padding-left: 35px; }

.models {
  /* Contacts overview */ }
  .models .cell.model-id {
    min-width: 99px;
    width: 30%; }
  .models .cell.first-name {
    min-width: 136px;
    width: 10%; }
  .models .cell.last-name {
    min-width: 157px;
    width: 10%; }
  .models .cell.age {
    min-width: 90px;
    width: 13%; }
  .models .cell.gender {
    min-width: 68px;
    width: 30%; }
  .models .cell.category {
    min-width: 103px;
    width: 30%; }
  .models .cell.phone-number {
    min-width: 144px;
    width: 30%; }
  .models .cell.city {
    min-width: 144px;
    width: 30%; }

.full-width {
  width: 100%; }

.content-block-persona {
  min-width: 100%;
  margin-bottom: 12px; }

.flex-container {
  display: flex; }

.projects {
  /* Contacts overview */ }
  .projects .cell.project-id {
    min-width: 292px;
    width: 30%; }
    .projects .cell.project-id strong {
      color: #ae1eac; }
  .projects .cell.client {
    min-width: 350px;
    width: 10%; }
  .projects .cell.phone-number {
    min-width: 150px;
    width: 30%; }
  .projects .cell.date {
    min-width: 105px;
    width: 30%; }

.new-group {
  margin-bottom: 18px; }

.grouped-models {
  display: inline-block; }

.projects-droparea {
  margin: 0; }
  .projects-droparea.ui-state-hover {
    background: #F9FBFE; }
  .projects-droparea.ui-droppable-hover {
    background: #C4D08B; }

.ui-draggable-handle {
  cursor: move; }

.ui-draggable-dragging {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999; }

.non-grouped-models {
  min-height: 50px; }

.remove-introduced-models {
  cursor: pointer; }

.grouped-models-container .group {
  display: flex;
  flex-direction: column;
  background-color: #F9FBFE; }
  .grouped-models-container .group .group-title {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #617b98;
    font-style: italic;
    margin: 10px 18px;
    display: flex;
    justify-content: space-between; }
  .grouped-models-container .group .grouped-models {
    margin-top: 0; }

.content-block ul {
  padding: 0;
  margin-bottom: 18px; }
  .content-block ul li {
    list-style: none;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 200;
    line-height: 26px;
    margin: 0 18px; }

.content-block-models {
  display: flex;
  flex-direction: column; }
  .content-block-models h2 {
    margin-bottom: 0 !important; }
  .content-block-models ul {
    margin: 0;
    flex-grow: 1;
    padding: 10px 0; }

.selected-booked-model,
.payment-booked-model {
  display: flex;
  justify-content: space-between;
  cursor: pointer; }

.open-close .grouped-models {
  display: none; }

.new-group-input {
  display: flex;
  padding: 0 18px;
  justify-content: center;
  align-items: center;
  align-content: center; }
  .new-group-input .groupname {
    flex-grow: 1; }
  .new-group-input .plus-add-models-to-group {
    flex-shrink: 0;
    margin-left: 18px;
    margin-bottom: 0 !important; }

.finances {
  /* Contacts overview */ }
  .finances .cell.project-id {
    min-width: 235px;
    width: 30%; }
  .finances .cell.client {
    min-width: 240px;
    width: 10%; }
  .finances .cell.invoice {
    min-width: 161px;
    width: 30%; }
  .finances .cell.invoice-number {
    min-width: 124px;
    width: 30%; }
  .finances .cell.invoice-date {
    min-width: 112px;
    width: 30%; }
  .finances .cell.amount {
    min-width: 110px;
    width: 30%; }
  .finances .cell.reminder {
    min-width: 87px;
    width: 30%; }

.login {
  width: 35%;
  margin-top: 35px;
  background: #627A97;
  border-radius: 3px;
  border: 1px solid #808080;
  padding: 15px; }
  .login .form-group {
    margin-bottom: 15px; }
    .login .form-group label {
      display: block;
      margin-bottom: 5px; }
    .login .form-group input {
      width: 100%;
      border-radius: 3px;
      padding: 5px;
      border: 1px solid #808080;
      height: 35px; }
  .login .button {
    border: 1px solid #2e6da4;
    height: 35px;
    border-radius: 3px;
    background: #337ab7;
    color: #ffffff;
    padding: 10px; }
  .login .text-right {
    text-align: right; }

/*
 |  Global Partials
 */
* {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased; }

html {
  height: 100%;
  font-family: "Helvetica Neue", sans-serif; }

body {
  background-color: #F1F1F2;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column; }

a {
  color: #76a9e4;
  text-decoration: none; }

.row {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  padding: 0 60px;
  transition: 300ms ease-in-out; }
  @media screen and (max-width: 1300px) {
    .row {
      padding: 0 20px; } }

.main-content {
  flex-grow: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  flex-direction: column;
  display: block;
  margin-top: 118px; }

#loading-indicator {
  display: none;
  align-self: center;
  float: left;
  fill: #617b98;
  -webkit-animation: spin 0.5s linear infinite;
  -moz-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite; }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

#advanced-model-search-form .fieldsets-container {
  flex-wrap: wrap; }

#advanced-model-search-form .fieldset {
  margin: 6px; }

#advanced-model-search-form small,
#advanced-model-search-form .form-notice {
  color: #808080;
  font-size: 12px;
  font-style: italic; }

#advanced-model-search-form .fieldset-content {
  padding: 1rem; }
  #advanced-model-search-form .fieldset-content > label,
  #advanced-model-search-form .fieldset-content > .fake-label {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%; }

#advanced-model-search-form input[type="text"],
#advanced-model-search-form input[type="number"] {
  padding: 0.5rem; }

#advanced-model-search-form label, #advanced-model-search-form .fake-label {
  display: block; }
  #advanced-model-search-form label:hover, #advanced-model-search-form .fake-label:hover {
    background-color: white; }

#advanced-model-search-form .input-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  #advanced-model-search-form .input-row > * {
    margin: 5px;
    flex: 1 0 auto;
    max-width: 100%; }

#advanced-model-search-form .input-column > * {
  width: 100%; }

input[type='search']::-webkit-search-cancel-button {
  cursor: pointer; }
