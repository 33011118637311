/*
 |  Mixins
 */
@import 'mixins/placholder';

/*
 |  Fonts
 */
@import 'fonts/model-support';
@import 'fonts/roboto';

/*
 |  Elements
 */
@import 'elements/button';
@import 'elements/table';
@import 'elements/form';
@import 'elements/alert';

/*
 |  Pages
 */
@import 'pages/dashboard';
@import 'pages/auth/login';

/*
 |  Global Partials
 */
@import 'partials/global';
