* {
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
}

html {
    height: 100%;
    font-family: "Helvetica Neue", sans-serif;
}

body {
    background-color: #F1F1F2;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

html, body {
}

a {
    color: #76a9e4;
    text-decoration: none;
}

.row {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-direction: row;
    padding: 0 60px;
    transition: 300ms ease-in-out;

    @media screen and (max-width: 1300px) {
        padding: 0 20px;
    }
}

.main-content {
    flex-grow: 1;
    padding-top: 25px;
    padding-bottom: 25px;
    flex-direction: column;
    display: block;
    margin-top: 118px
}

#loading-indicator {
    display: none;
    align-self: center;
    float: left;
    fill: #617b98;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

#advanced-model-search-form {

    .fieldsets-container {
        flex-wrap: wrap;
    }

    .fieldset {
        margin: 6px;
    }

    small,
    .form-notice
    {
        color: #808080;
        font-size: 12px;
        font-style: italic;
    }
    .fieldset-content {
        padding: 1rem;

        > label,
        > .fake-label
        {
            margin-left: 5px;
            margin-right: 5px;
            max-width: 100%;
        }
    }

    input[type="text"],
    input[type="number"]
    {
        padding: 0.5rem;
    }

    label, .fake-label {
        display: block;

        &:hover {
            background-color: white;
        }
    }

    .input-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center ;

        > * {
            margin: 5px;
            flex: 1 0 auto;
            max-width: 100%;
        }
    }

    .input-column {
        > * {
            width: 100%;
        }
    }
}

input[type='search']::-webkit-search-cancel-button {
    cursor: pointer;
}

