.alert {
    width: 35%;
    margin: 20px auto;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    list-style: none;
}

.warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}