.item-content-container {
    ul.tabs {
        margin: 0;
        padding-left: 20px;
        height: 47px;

        li {
            display: inline-block;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #bdbebf;
            height: 48px;
            line-height: 32px;
            padding: 13px 1px 0;
            margin: 0 17px;

            &.active {
                color: #617b98;
                border-bottom: 2px solid #617b98;
            }
        }
    }
    .tab-content {
        display: none;
        border-bottom: 1px solid #e0e0e1;

        &:first-of-type {
            display: flex;
        }
    }
}