.table {
    $cell-x-padding: 10px;

    display: flex;
    flex-direction: column;

    .table-row {
        display: flex;
        flex-grow: 1;
        border-bottom: 1px solid #E0E0E1;
        padding: 0 $cell-x-padding;

        .cell {
            font-size: 15px;
            padding: 16px $cell-x-padding;
            color: #3c3a3a;
            transition: color .2s ease-in-out;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;

            &.toggle {
                width: 45px;
                flex-shrink: 0;
                flex-grow: 0;
                text-align: center;
            }

            &.actions {
                display: flex;
                flex-grow: 1;
                flex-shrink: 1;
                justify-content: flex-end;
                padding: 0;
                min-width: 100px;

                &.actions-2 {
                    min-width: 65px;
                }
                &.actions-4 {
                    min-width: 135px;
                }
                &.actions-5 {
                    min-width: 205px;
                }
                button {
                    padding: 0 10px;
                    font-size: 14px;
                    transition: all .1s ease-in-out;
                    transform: scale(1);
                    transform-origin: center center;

                    &:hover {
                        color: #617B98;
                        transform: scale(1.1);
                    }
                }

                .icon-globe {
                    width: 20px;
                    display: flex;
                    height: 20px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }
                .icon-globe.green {
                    color: white;
                    background-color: #C4D08B;
                }
            }
            .grey {
                color: #929292;
            }
            .green {
                color: green;
            }
        }
    }

    > header.table-row,
    > footer.table-row {
        background-color: #F6F6F6;

        .cell {
            color: #3c3a3a;

            &.sortable {
                color: #617B98;
                cursor: pointer;
                user-select: none;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                position: relative;

                &.active {
                    font-weight: 500;

                    &:after {
                        font-size: 10px;
                        position: absolute;
                        content: '\63';
                        font-family: "model-support" !important;
                        font-style: normal !important;
                        font-weight: normal !important;
                        font-variant: normal !important;
                        text-transform: none !important;
                        speak: none;
                        line-height: 18px;
                        -webkit-font-smoothing: antialiased;
                        padding-left: 4px;
                    }
                }
                &.active-desc {
                    &:after {
                        content: '\62';
                    }
                }
            }
        }
    }
    > main > section,
    #advanced-model-search-results > main > section {
        display: flex;
        background-color: white;

        &:nth-child(even) {
            background-color: #F9FBFE;
        }

        &:hover {
            .cell {
                color: #607a99;
            }
        }

        &.collapsible {
            flex-direction: column;


            > input[type=checkbox] {
                display: none;
            }

            > main {
                display: none;
                background-color: #fff;
            }

            .icon-arrowdown {
                transition: transform .05s ease-in-out;
                transform: rotate(-90deg);
                transform-origin: center center;
                display: inline-block;
            }

            // Open
            &.open {
                .icon-arrowdown {
                    transform: rotate(0deg);
                }
            }
        }
    }

    > footer.table-row {
        border-bottom: 1px solid #DBD8D8;
        border-radius: 4px;

        .cell {
            text-align: center;
            flex-grow: 1;
        }
    }
}
.indication-dot {
    display: inline-block;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background-color: #dbd8d8;

    &.red {
        background-color: #dd2323;
    }
    &.purple {
        background-color: #ae1eac;
    }
    &.blue {
        background-color: #1e79ae;
    }
    &.green {
        background-color: #00cb63;
    }
}
