.finances {
  /* Contacts overview */
  .cell {
    &.project-id {
      min-width: 235px;
      width: 30%;
    }
    &.client {
      min-width: 240px;
      width: 10%;
    }
    &.invoice {
      min-width: 161px;
      width: 30%;
    }
    &.invoice-number {
      min-width: 124px;
      width: 30%;
    }
    &.invoice-date {
      min-width: 112px;
      width: 30%;
    }
    &.amount {
      min-width: 110px;
      width: 30%;
    }
    &.reminder {
      min-width: 87px;
      width: 30%;
    }
  }
}