.models {
  /* Contacts overview */
  .cell {
    &.model-id {
      min-width: 99px;
      width: 30%;
    }
    &.first-name {
      min-width: 136px;
      width: 10%;
    }
    &.last-name {
      min-width: 157px;
      width: 10%;
    }
    &.age {
      min-width: 90px;
      width: 13%;
    }
    &.gender {
      min-width: 68px;
      width: 30%;
    }
    &.category {
      min-width: 103px;
      width: 30%;
    }
    &.phone-number {
      min-width: 144px;
      width: 30%;
    }
    &.city {
      min-width: 144px;
      width: 30%;
    }
  }
}
.full-width {
  width: 100%;
}
.content-block-persona {
  min-width: 100%;
  margin-bottom: 12px;
}
.flex-container {
  display: flex;
}