.navigation.top {
    background-color: white;
    width: 100%;
    padding: 30px 0;
    flex-shrink: 0;
    position: fixed;
    z-index: 10;

    .row {
        justify-content: space-between;
    }

    hgroup {
        align-self: flex-start;
    }

    .title,
    .subtitle {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
    }
    .title {
        font-size: 30px;
        color: #535151;
    }
    .subtitle {
        font-size: 19px;
        color: #989494;
    }

    .button {
        align-self: center;
        flex-shrink: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        border: 1px solid #989494;
        border-radius: 3px;
        padding: 9px 20px;
    }
}
