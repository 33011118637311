.contacts {
    /* Contacts overview */
    .cell {
        &.company-name {
            min-width: 257px;
            width: 30%;
        }
        &.first-name {
            min-width: 142px;
            width: 10%;
        }
        &.last-name {
            min-width: 147px;
            width: 10%;
        }
        &.phone-number {
            min-width: 147px;
            width: 13%;
        }
        &.email {
            min-width: 270px;
            width: 30%;
        }
    }
}
.table {
    .table-row {
        .cell {
            &.contactperson-indenting {
                padding-left: 35px;
            }
        }
    }
}
