.form-container {
  .action-bar {
    background: #f6f6f6;
    border-bottom: 1px solid #ece8e8;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 29px;

    &:last-of-type {
      border-top: 1px solid #ece8e8;
    }
  }
  h1, button {
    line-height: 31px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  h1 {
    display: inline-block;
    color: #20587b;
    margin: 0;
    line-height: 48px;
  }
  .btn-group {
    button {
      padding: 1px 29px 2px;
    }
    button.cancel {
      color: #a8abae;
    }
    button.store {
      color: #fff;
      background: #5f9be2;
    }
  }
  .fieldsets-container {
    padding: 33px 35px 0;

    &:last-of-type {
      padding-bottom: 71px;
    }
  }
  .fieldsets-container, .fields-splits-container, .form-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.justify-flex-start {
      justify-content: flex-start;
    }
  }
  section.fieldset {
    background: #fafafa;
    border: 1px solid #ece8e8;
    padding: 0;
    margin-right: 13px;
    flex: 1;

    hr {
      border: none;
      border-bottom: 1px solid #ece8e8;
    }

    &.for-textarea {
      display: flex;
      flex-direction: column;
    }
    &:last-of-type {
      margin-right: 0;
    }

    .fields-split {
      margin: 21px 16px 35px;
    }
    h2 {
      border-bottom: 1px solid #ece8e8;
      line-height: 43px;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      padding-left: 17px;
      margin: 0;
      color: #20587b;
    }
    .warning {
      color: #dd2323;
      line-height: 30px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    input, label, .fake-label {
      box-sizing: border-box;
      line-height: 21px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    input,
    select,
    textarea
    {
      border: 1px solid #ddd;

      &:focus {
        border-color: #627A97;
      }
    }


    .form-line > input, .checkboxes-selected {
      border: 1px solid #ece8e8;
      color: #3e3c3c;
      width: 232px;
      min-height: 31px;
      padding: 0 5px;

      &:focus {
        border-color: #cac5c5;
      }
      &.in-error {
        border-color: #dd2323;
      }
    }
    label, .fake-label {
      padding-right: 20px;
      line-height: 31px;
      white-space: nowrap;
      display: inline-block;
      color: #3e3c3c;
    }
    textarea {
      border: 0;
      flex: 1;
      padding: 10px 15px;
      box-sizing: border-box;
      line-height: 21px;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
    }
    .fields {
      color: #3e3c3c;
      width: 232px;
      min-height: 31px;
      position: relative;

      &.fields-padding {
        padding: 0 5px;
      }
      label {
        padding-right: 10px;
      }
      .checkboxes-selected {
        color: #3e3c3c;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 31px;

        span:after {
          content: ', ';
        }
        span:last-of-type:after {
          content: '';
        }
      }
      .dropdown-closer {
        display: none;
        position: absolute;
        z-index: 2;
        top: 39px;
        right: 9px;
        cursor: pointer;
      }
      ul.checkboxes-dropdown {
        display: none;
        position: absolute;
        z-index: 1;
        margin: 0;
        padding: 0;
        top: 30px;
        left: 0;
        right: 0;
        background: #fff;
        border: 1px solid #ece8e8;

        li {
          border-bottom: 1px solid #ece8e8;
          list-style-type: none;
          line-height: 31px;
          padding-left: 10px;

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

// Form elements
form {
  input[type="checkbox"], input[type="radio"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  input[type="checkbox"] + label, input[type="radio"] + label {
    display: block;
    cursor: pointer;
    position: relative;
    color: #888;
    line-height: 17px;
  }
  input[type="checkbox"] + label {
    &:before {
      font-family: model-support;
      content: "\67";
      color: #bbb;
      display: inline-block;
      width: 16px;
      height: 20px;
      margin-right: 6px;
      vertical-align: top;
    }
  }
  input[type="radio"] + label {
    &:before {
      content: '';
      display: inline-block;
      border: 1px solid #ddd;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      margin-right: 10px;
      vertical-align: text-top;
    }
    &:after {
      content: '';
      position: absolute;
      background-color: transparent;
      border-radius: 50%;
      left: 3px;
      width: 11px;
      height: 11px;
      top: 10px;
      transition: background-color .2s linear 0s;
    }
  }
  input[type="checkbox"]:checked + label:before {
    font-family: model-support;
    content: "\66";
    color: #555;
  }
  input[type="radio"]:checked + label:after {
    background-color: #617b98;
  }
}
.styledSelect {
  position: relative;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  border: 1px solid #ece8e8;
  width: 232px;
  overflow: hidden;
  background-color: #fff;

  &.in-error {
    border-color: #dd2323;
    border-width: 3px;
  }

  &:after {
    font-family: model-support;
    content: "\64";
    color: #aaa;
    position: absolute;
    top: 0;
    line-height: 31px;
    right: 12px;
    font-size: 12px;
    text-align: right;
    pointer-events: none;
  }
}
.styledSelect select {
  padding: 4px 73px 4px 8px;
  width: 130%;
  border: none;
  color: #767676;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.styledSelect select:focus {
  outline: none;
}


.search-options-template {
  display: none;
}

.form-container {
  width: 100%;
}

.search-options-container .search-option-line {
  justify-content: flex-start;
}

.search-option-line .styledSelect {
  margin-right: 10px;
}

.advanced-search-option-values {
  display: none;

  &.show {
    display: inline-block;
  }
}

.search-button {
   color: #fff;
   background: #5f9be2;
   padding: 2px 20px;
  border-radius: 2px;

   &:hover {
     background: darken(#5f9be2, 5%);
   }
 }

.search-view-switch {
  color: #404040;
  background: #eee;
  padding: 2px 20px;
  border-radius: 2px;

  &:hover {
    background: darken(#5f9be2, 5%);
  }
}

.reset-button {
  color: #b8333a;
  background: #eee;
  padding: 2px 20px;
  border-radius: 2px;

  &:hover {
    background: darken(#eee, 5%);
  }
}

.delete-search-row {
  color: red;
  font-weight: normal;
}
