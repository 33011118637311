.navigation.bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    flex-shrink: 0;
    margin-top: 60px;

    .row {
        justify-content: flex-end;
    }

    .icon-logo-pixel-industries {
        font-size: 42px;
        color: #D9D9D9;
        text-decoration: none;
        transition: color .2s;
        padding-bottom: 5px;

        &:hover {
            color: black;
        }

        &:before {
            vertical-align: middle;
        }
    }
}
